.hero {
  min-height: calc(100vh - 110px);

  &-movil{
    display: none;
    width: 100%; 
    height: 100%;
    position: relative;
  }
  &-desktop{
    width: 100%; 
    height: 100%;
    position: relative;
    display: block;
  }
  &-decription {
    white-space: break-spaces;
    
  }

  .hero-bg {
    z-index: -1;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(20, 47, 57, 0.2);
    }
  }
  .flickity-slider{
    .flickity-cell{
      &:not(:first-child){
        top: -100%;
      }
    }
  }
  .flickity-page-dots {
      position: absolute;
      width: 100%;
      bottom: 20px;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      line-height: 1;
      z-index: 999;
      .flickity-page-dot {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 0 8px;
        background: #fff;
        border-radius: 50%;
        opacity: .5;
        cursor: pointer;
        border: none;
        font-size: 0px;
        &.is-selected{
          opacity: 1;
        }
      }
  }

  a {
    width: fit-content;
  }

  video{
    position: relative;
    top:0;
    right:0;
    left:0;
    bottom:0;
    object-fit: cover;
    width: 100%; 
    height: 100%;
    
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .card-wrapper {
      background: rgba(61, 183, 205, 0.05);
      backdrop-filter: blur(10px);
    }
  }

  // Fallback for Firefox (not supporting backdrop-filter)
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .card-wrapper {
      background: rgba(61, 183, 205, 0.6);
      backdrop-filter: blur(10px);
    }
  }

  .img-wrapper {
    height: 100%;
    div {
      height: 100%;
    }
  }
  &-title{
    color: white;
    max-width: 650px;
    text-align: center;
    margin: 0 auto;
  }
  .item-carousel{
    width: 100%;
    height: calc(100vh - 110px);
    background-color: white;
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    .hero-description{
      position: absolute;
      display: flex;
      justify-content: center;
      align-self: center;
      flex-direction: column;
    }
    img{
      object-fit: cover;
    }
   
  }
  .a-button{
    padding: 12px 36px;
    color: #141C3D;
    display: block;
    background-color: white;
    margin: 0 auto;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
  }
  &-description{
    position: relative; 
    z-index: 20;
  }
}

@media (max-width: 767px) {
  .hero p.decription, .hero h1 {
    text-align: center;
  }
  .hero-movil{
    display: block;
  }
  .hero-desktop{
    display: none;
    video{
      display: none;
    }
  }
 
}
