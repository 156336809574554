.Collapsible {
  border: 2px solid $primary;
  color: $primary;
  padding: 1rem 1rem 0 1rem;

  .Collapsible__trigger {
    width: calc(100% - 1.75rem);
    display: block;
    position: relative;

    &:after {
      font-family: 'FontAwesome';
      content: '\f107';
      position: absolute;
      right: -1rem;
      top: 0px;
      display: block;
      font-size: 1.7rem;
      transition: transform 500ms;
    }
  
    &.is-open {
      &:after {
        transform: rotate(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

  hr {
    width: 100%;
    height: 2px;
    background-color: $primary;
  }

  .Collapsible__contentOuter {
    margin-top: 1rem;
  }
}

.faq-fn-default {
  font-size: 1.5rem !important;
}

.Collapsible .Collapsible__trigger::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 16px;
  border-bottom: 3px solid;
  border-right: 3px solid;
  transform: rotate(45deg);
  top: 2px
}

.Collapsible .Collapsible__trigger.is-open::after {
  transform: rotate(225deg);
}
