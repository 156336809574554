// Custom variables
@import "../styles/variables";
@import "../styles/fonts";

//BOOTSTRAP MODULES
@import "../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";

@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/placeholders";
@import "../node_modules/bootstrap/scss/carousel";

@import "../node_modules/bootstrap/scss/pagination";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";
// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";
 // END BOOTSTRAP

// Custom Mixins
@import "mixins/space";
@import "mixins/buttons";

@import "../styles/main";

@import "../styles/nav";
@import "../styles/hero";
@import "../styles/footer";
@import "../styles/header";
@import "../styles/newsletter";
@import "../styles/brands";

@import "../styles/faq";


.input-contact:focus {
  outline: none;
}

.error:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 28, 28, 0.25) !important;
}

.success:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 189, 43, 0.25) !important;
}

.currentScroll {
  background-color: #141c3d !important;
  color: white !important;
  justify-content: start;
  align-items: center;
}

.modal-dialog.modal-small{
  position: absolute;
  width: 350px;
  bottom: 8em;
  right: 2em
}
.modal{
  &-whats{

    textarea{
       resize: none;
    }
    .modal-header{
      background: rgba(38,92,84,1);
      text-align: center;
      display: flex;
      border-radius: inherit;
      justify-content: center;
      strong{
        color: white;
        max-width: 340px;
        text-align: start;
      }
    }
   
    &-wrapper{
      width: 100%;
      background: rgba(235,229,222,1);
      .btn{
        width: 100%;
        border-radius: .5em;
        color: white;
        background: rgba(83,164,81,1);
      }
      display: flex;
      flex-direction: column;
      padding: 1em;
      justify-content: center;
    }
  } 
}
.fancybox__container{
  z-index: 10000 !important;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 8px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0.50;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}


.brand-icon {
  margin-bottom: 20px;
}
.carousel-items {
  width: 100%;
  height: 500px;
}
.carousel-items a {
  margin: 0 50px 120px;
  display: block;
  box-shadow: 11px 14px 14px -7px rgba(0,0,0,0.36);
  text-decoration: none;

}

.carousel-items .title {
  font-size: 20px;
  font-weight: bold;
}
.carousel-items .description {
  font-size: 16px;
  font-weight: 300;
  padding: 20px;
}

@media screen and (min-width: 767px) {

  .carousel-items .title {
    font-size: 28px;
  }
  .carousel-items .subtitlefontSize {
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) {
  .carousel-items a {
    //margin: 0 100px 100px;
  }
  .carousel-items .description {
    padding: 24px;
    font-size: 20px;
  }
  
}
@media screen and (max-width: 600px) {
  .brand-icon {
    max-width: 370px !important;
    left: 0px !important;
  }
  
  
}



@media screen and (max-width: 992px) {
  .modal-dialog.modal-small{
    position: relative;
    width: auto;
    top: 30vh;
    right: 0;
  }
  #caraousel-imagel {
    width: 100% !important;
  }
}
.slick-track {
  display: flex !important;
}
.slick-track .slick-slide {
  display: flex !important;
  height: auto !important;
  align-items: center !important;
  justify-content: center !important;
}

.brand-yachts-container {
  display: grid;
  grid-template-columns: 1fr;
}
.title-mobile {
  font-size: 40px !important;
}

@media screen and (min-width: 767px) {
  .brand-yachts-container {
    
    grid-template-columns: 1fr 1fr !important;
  }
  .title-mobile {
    font-size: 35px !important;
  }
}

@media screen and (min-width: 1080px){
  .brand-yachts-container {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}


