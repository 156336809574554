.modal{
    &-body{
        display: flex;
        padding: 0;
    }
    &-content{
        border-radius: 0;
    }
    &-text{
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 4em 2em;
        justify-content: center;
        text-align: center;
        h3{
            color: $primary;
        }
    }
    a{
        width: fit-content;
        margin: .5em auto;
        padding-left: 2em;
        padding-right: 2em;
        
    }
    &-image{
        position: relative;
        width: 50%;
        min-height: 500px;
    }
    &-label{
        position: fixed;
        z-index: 100;
        width: 100%;
        background: $primary;
        justify-content: center;
        color: $white;
        cursor: pointer;
        display: flex;
        padding: .5em;

        top: 135px;
        @media only screen and (max-width: 1400){
            top: 135px;
        }
        span{
            width: 90%;
            margin: auto;
            text-align: center;
        }
        &-button{
            z-index: 101;
            position: absolute;
            background-repeat: no-repeat;
            right: 10px;
            height: 50%;
            background-size: 1em 1em;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.109' height='17' viewBox='0 0 17.109 17'%3E%3Cpath id='times' d='M9.7,104l5.128-5.128,1.058-1.058a.4.4,0,0,0,0-.566l-1.132-1.132a.4.4,0,0,0-.566,0L8,102.3,1.815,96.117a.4.4,0,0,0-.566,0L.117,97.249a.4.4,0,0,0,0,.566L6.3,104,.117,110.186a.4.4,0,0,0,0,.565l1.132,1.132a.4.4,0,0,0,.566,0L8,105.7l5.128,5.128,1.058,1.058a.4.4,0,0,0,.566,0l1.132-1.132a.4.4,0,0,0,0-.565Z' transform='translate(0.555 -95.5)' fill='%23f4f4f4' stroke='rgba(0,0,0,0)' stroke-miterlimit='10' stroke-width='1' opacity='0.6'/%3E%3C/svg%3E%0A");
        }
    }
    &-trigger{
        position: absolute;
        top: 150vh;
        display: block;

    }
}

.newsletterFooter{
    display: flex;
    min-height: 300px;
    &-image{
        position: relative;
        width: 50%;
    }
    &-text{
        padding: 1em 10%;
        width: 50%;
        background: #F0F0F0;
        flex-direction: column;
        display: flex;
        justify-content: center;
        h3{
            color: $primary;
        }
        span{
            display: flex;
            gap: .25em;
        }
    }

}
@media only screen and (max-width: 999){
    .modal{
        &-label{
            top: auto;
            bottom: 0;
        }
    }
}

@media only screen and (max-width: 769px) {
    .modal{
        &-label{
           
            top: auto;
            bottom: 0;
            span{
                width: 80%;
            }
            @media only screen and (max-width: 500px){
              bottom: auto;
              top: 82px;
            }
        }
        &-body{
            flex-direction: column;
        }
        &-text{
            width: 100%;
            button{
                width: 100%;
            }
        }
        &-image{
            width: 100%;
            min-height: 300px;
        }
    }
    .newsletterFooter{
        flex-direction: column;
        &-text{
            width: 100%;
           
            span{
                flex-direction: column;
            }
        }
        &-image{
            width: 100%;
            height: 200px;
        }
    }
}