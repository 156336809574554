// Content Margin Spaces
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (padding: space) {
      @each $size, $length in $content-spacers {
        .#{$abbrev}#{$infix}-#{$size},
        .#{$abbrev}-top#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}#{$infix}-#{$size},
        .#{$abbrev}-bottom#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
      }
    }
  }
}
