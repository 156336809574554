@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("/fonts/AvenirLTStd-Book.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Avenir-Medium.woff2") format("woff2"),
    url("/fonts/Avenir-Medium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Avenir-Light.woff2") format("woff2"),
  url("/fonts/Avenir-Light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Avenir-Heavy.woff2") format("woff2"),
    url("/fonts/Avenir-Heavy.woff") format("woff");
  font-display: swap;
}


// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-xs: 0.75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2.2rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.1rem !default;
$h5-font-size: 1.05rem !default;
$h6-font-size: 1rem !default;

// $headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.5 !default;
$headings-color: inherit !default;

$display-font-weight: 400;
$display-font-sizes: (
  1: 8rem,
  2: 5.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);
