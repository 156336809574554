header {
  min-height: 12.5rem;
  padding: .75rem 0;
  display: flex;
  align-items: flex-end;
  position: relative;
  background: rgba(20, 47, 57, 0.5);

  h1 {
    color: white;
    font-weight: 700;
  }

  .breadcrumbs {
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 18px;
    list-style: none;
  
    li {
      &:not(:last-child)::after {
        content: "›";
        padding: 0 .5rem;
        color: white;
      }
  
      a {
        color: white;
        text-decoration: none;
        text-transform: capitalize;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .header-img {
    z-index: -1;
    img{
      object-fit: cover;
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    padding: 1.5rem 0;
  }
}

@media only screen and (min-width: 992px) {
  header {
    min-height: 15rem;
    padding: 2rem 0;
  }
}

@media only screen and (min-width: 1200px) {
  header {
    min-height: 17.5rem;
    padding: 3rem 0;
    h1 {
      font-size: 50px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  header {
    min-height: 20rem;
    padding: 4rem 0;
  }
}


.header-styles {
  background-color: white !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #53575A ;
}
#link.nav-top {
  color: #fff !important;
} 
.tch-button {
  text-transform: uppercase !important;
  font-weight: bold !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

#navbar-nav .nav-link {
  text-align: center !important;
}

.navbar-collapse.collapse.show {
  display: flex !important;
  flex-direction: column !important;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px !important;
}
#logo-mobile {
  display: flex;
}
#logo-desktop {
  display: none;
}
#brand-logos {
  flex-direction: column;
}
#logos-disposition {
  flex-direction: row;
}

.nav-mobile-container {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

@media screen and (min-width: 1200px ) {
  .nav-mobile-container {
    
    width: auto;
    flex-direction: column;
  }
  .brand-logo-container {
    width: 160px !important;
  }

  #logos-disposition {
    flex-direction: column;
  }
  #brand-logos {
    flex-direction: row;
  }
  #logo-mobile {
    display: none;
  }
  #logo-desktop {
    display: flex;
  }
}
@media screen and (min-width: 992px) {
  
  .navbar-collapse.collapse.show {
    flex-direction: row !important;
    justify-content: end !important;
  }
  .button-container {
    width: auto;
    display: inline;
  }

}
.navbar-collapse.collapse.show .navbar-nav {
  margin-left: 0 !important;
}


.btn-outline-primary.transparent {
  color:white !important;
  border: white solid 1px !important;
}

.btn-outline-primary.transparent:hover {
  border: #111D3B solid 1px !important;
}
svg {
  transition: .3s;
}


@media only screen and (max-width: 992px) {
  .navbar {
    background: rgba(0, 0, 0, 0.0) !important;
  }
  .navbar.dark {
    background: rgba(0, 0, 0, 0.4) !important;
  }
  .navbar.sticky-nav {
    background: white !important;
  }
  .navbar.header-styles {
    background: white !important;
  }

  .navbar .navbar-toggler {
    color: white !important;
  }
  .navbar.sticky-nav .navbar-toggler {
    color: black !important;
  }
  .navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  .navbar.sticky-nav .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
  .navbar.header-styles .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='black' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 650px) {  
  .nav-mobile-container {
    
    display: flex;
  }
  .brand-icon.icon-1 {
    width: 100% !important;
  }
  .brand-icon.icon-2 {
    width: 100% !important;
  }
}


