h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
}

.list-style-custom {
  li {
    padding-bottom: 0.5rem;
    &:last-child {
      padding-bottom: 0;
    }
    &::marker {
      color: var(--bs-primary);
      font-size: 1.5rem;
    }
  }
}

.list-pointer {
  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  li {
    position: relative;
    padding-left: 1rem;
  }
  li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0px;
    width: 7px;
    height: 11px;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.762396 0.537298C0.798119 0.501485 0.840557 0.47307 0.887278 0.453683C0.934 0.434296 0.984087 0.424316 1.03467 0.424316C1.08526 0.424316 1.13534 0.434296 1.18206 0.453683C1.22879 0.47307 1.27122 0.501485 1.30695 0.537298L5.92179 5.15214C5.9576 5.18786 5.98602 5.2303 6.00541 5.27702C6.02479 5.32375 6.03477 5.37383 6.03477 5.42442C6.03477 5.475 6.02479 5.52509 6.00541 5.57181C5.98602 5.61853 5.9576 5.66097 5.92179 5.69669L1.30695 10.3115C1.23474 10.3837 1.13679 10.4243 1.03467 10.4243C0.932548 10.4243 0.834608 10.3837 0.762396 10.3115C0.690184 10.2393 0.649615 10.1414 0.649615 10.0393C0.649615 9.93714 0.690184 9.8392 0.762396 9.76698L5.10573 5.42442L0.762396 1.08185C0.726582 1.04613 0.698168 1.00369 0.67878 0.956967C0.659393 0.910246 0.649414 0.860158 0.649414 0.809574C0.649414 0.75899 0.659393 0.708903 0.67878 0.662181C0.698168 0.615459 0.726582 0.573022 0.762396 0.537298Z' fill='%23E8E9EC'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 7px 11px;
    margin-top: 7px;
  }
}

.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-item:last-child .page-link {
  border-radius: 0 !important;
}

.hideable-title {
  width: calc(100% - 1px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.space-prewrap {
  white-space: pre-wrap;
}

.id-tag {
  visibility: hidden;
  width: 0;
  height: calc(100% - 10rem);
  position: absolute;
  top: -10rem;
}

.PhoneIcon {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all .25s linear;
  background-color: #111D3B;
  border: 1.5px solid white;
  &:hover {
    box-shadow: 0px 4px 8px 1px rgba(0,0,0,.25);
  }

}
.WhatsappIcon {
  position: fixed;
  border: 1.5px solid white;
  bottom: 5rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all .25s linear;
  background-color: #111D3B;
  &:hover {
    box-shadow: 0px 4px 8px 1px rgba(0,0,0,.25);
  }
}
.WhatsappIcon svg {
  transform: scale(2);
}

.swiper-button{
  &-next, &-prev{
    background-color: #111d3ba5;
    width: 40px !important;
    height:  40px !important;
    border-radius: 100%;
  }
 
}

@media only screen and (min-width: 768px) {
  .PhoneIcon {
    bottom: 2rem;
    right: 2rem;
  }
  .WhatsappIcon {
    right: 2rem;
    bottom: 6rem;
  }
}

@media only screen and (min-width: 992px) {
  .id-tag {
    height: calc(100% - 9rem);
    top: -9rem;
  } 
}
