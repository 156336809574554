.footer-nav {
  li:not(:last-child):after {
    content: "|";
    padding: 0 0.6rem;
  }
}

footer{
  position: relative;
  z-index: 2;
}

.footer-list a {
  color: white;
  text-decoration: none;

}

.footer-list li {
  list-style: none;
  margin-bottom: 8px;

}
ul.list-unstyled{
  gap: .75em;
}
@media screen and (min-width: 768px) {
  .footer-list li {
    text-align: center;
  }
  .title-alignment {
    text-align: center;
  }
  .flex-alignment {
    justify-content: center;
  }
}


.footer-list svg {
  display: inline-block;
  margin-right: 8px;
}
.footer-list span {
  text-align: center;
}
.footer-list {
  padding-left: 0;
}
.footer-list a {
  text-align: center;
}
.list-point {
  display: inline-block;
  width: 7px;
  height: 11px;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.762396 0.537298C0.798119 0.501485 0.840557 0.47307 0.887278 0.453683C0.934 0.434296 0.984087 0.424316 1.03467 0.424316C1.08526 0.424316 1.13534 0.434296 1.18206 0.453683C1.22879 0.47307 1.27122 0.501485 1.30695 0.537298L5.92179 5.15214C5.9576 5.18786 5.98602 5.2303 6.00541 5.27702C6.02479 5.32375 6.03477 5.37383 6.03477 5.42442C6.03477 5.475 6.02479 5.52509 6.00541 5.57181C5.98602 5.61853 5.9576 5.66097 5.92179 5.69669L1.30695 10.3115C1.23474 10.3837 1.13679 10.4243 1.03467 10.4243C0.932548 10.4243 0.834608 10.3837 0.762396 10.3115C0.690184 10.2393 0.649615 10.1414 0.649615 10.0393C0.649615 9.93714 0.690184 9.8392 0.762396 9.76698L5.10573 5.42442L0.762396 1.08185C0.726582 1.04613 0.698168 1.00369 0.67878 0.956967C0.659393 0.910246 0.649414 0.860158 0.649414 0.809574C0.649414 0.75899 0.659393 0.708903 0.67878 0.662181C0.698168 0.615459 0.726582 0.573022 0.762396 0.537298Z' fill='%23E8E9EC'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 7px 11px;
  margin-top: 7px;
  margin-right: 8px;
}

.proficientBadge {
  display: flex;
  justify-content: center;
}

.proficientBadge proficient-badge {
  padding: 8px;
}
