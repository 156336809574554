@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant(
      $value,
      $value,
      $hover-color: $primary,
      $hover-background: $white,
      $hover-border: $primary,
      $active-background: lighten($value, 10%),
      $active-border: lighten($value, 12.5%)
    );
  }
}
